.performance-header-grid .icon-box {
  font-size: 3.5em;
}
@media screen and (max-height: 1000px) {
  .performance-header-grid .performance-header-card-content,
  .performance-header-grid .performance-header-card-content:last-child {
    padding: 5px;
  }
  .performance-header-grid .icon-box {
    font-size: 1.5em;
  }
}
