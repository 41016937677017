.title {
  text-align: center;
}

.form {
  border: solid black 1px;
}

.elem {
  overflow: hidden;
  display: inline-block;
  width: 150px;
  height: 20px;
}
